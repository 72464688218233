
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import { breakpoint } from '~/composables/breakpoint';
import ProductSlider from '~/domains/main/components/ProductSlider';
import useFetchCategory from '~/composables/useFetchCategory';

export default defineComponent({
  name: 'MainAllProductsSection',
  components: { ProductSlider },
  setup() {
    const {
      allCategories,
      currentCategory,
      category,
      fetchCategories,
      fetchCategoryBySlug,
      setDefaultCategory,
    } = useFetchCategory();
    const { isBiggerThanBreakpoint } = breakpoint('md');
    const isLoading = ref(false);

    useFetch(async () => {
      await fetchCategories();
      setDefaultCategory();
      await fetchCategoryBySlug(currentCategory.value.slug);
    });

    const handleChangeCategory = async (item) => {
      isLoading.value = true;
      currentCategory.value = item;
      await fetchCategoryBySlug(currentCategory.value.slug);
      isLoading.value = false;
    };

    return {
      category,
      allCategories,
      isBiggerThanBreakpoint,
      currentCategory,
      isLoading,
      fetchCategories,
      handleChangeCategory,
    };
  },
});
