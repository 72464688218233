import { render, staticRenderFns } from "./ProductSlider.vue?vue&type=template&id=763cd058&scoped=true"
import script from "./ProductSlider.vue?vue&type=script&lang=js"
export * from "./ProductSlider.vue?vue&type=script&lang=js"
import style0 from "./ProductSlider.vue?vue&type=style&index=0&id=763cd058&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763cd058",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ProductCard.vue').default,UIIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIIcon.vue').default,AppSkeletonSliderCards: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppSkeletonSliderCards.vue').default,AppSkeletonCatalog: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppSkeletonCatalog.vue').default})
