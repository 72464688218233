
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UIColorSelectItem',
  props: {
    to: {
      type: String,
      default: '',
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
    shadow: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    tag: {
      type: String,
      default: 'a',
    },
  },
});
