
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UIColorSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'a',
    },
  },
  setup(props, { emit }) {
    const actualItems = ref(
      [...props.items].map((item) => ({
        ...item,
        shadow: `0 0 0 ${item.color ? item.color : item.feature_value}`,
        color: item.color ? item.color : item.feature_value,
      }))
    );

    const changeShadow = (index, color) => {
      actualItems.value[index].shadow = color;
    };

    const handleClick = (index) => {
      actualItems.value = actualItems.value.map((similar, i) =>
        index === i
          ? { ...similar, selected: true }
          : { ...similar, selected: false }
      );

      emit('click', index);
    };

    const handleMouseenter = ({ index, color }) => {
      if (!props.isClickable) return;

      changeShadow(index, `0 -4px 10px 2px ${color}`);
    };

    const handleMouseleave = ({ index, color }) => {
      if (!props.isClickable) return;

      changeShadow(index, `0 0 0 ${color}`);
    };

    return {
      actualItems,
      changeShadow,
      handleClick,
      handleMouseenter,
      handleMouseleave,
    };
  },
});
