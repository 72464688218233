
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    variant: {
      type: String,
      default: 'big',
    },
  },
  setup(props) {
    const activeVariation = 0;
    const similarProducts = computed(
      () => props?.product?.similar_products?.[0]?.items
    );
    const activeSimilarProduct = ref(
      similarProducts?.value?.find((item) => item.selected)
    );

    const productSlug = computed(() =>
      activeSimilarProduct?.value
        ? `/product/${activeSimilarProduct.value.slug}`
        : `/product/${props.product.slug}`
    );

    const productSKU = computed(() =>
      activeSimilarProduct?.value
        ? activeSimilarProduct.value.sku
        : props.product.sku
    );

    const productImage = computed(() =>
      activeSimilarProduct?.value
        ? activeSimilarProduct.value?.photo_url
        : props.product.variations[activeVariation]?.images?.[0]
    );

    const colors = computed(() =>
      props.product.variations.map((item) => item.color)
    );

    const handleChangeSimilarProduct = (index) => {
      activeSimilarProduct.value = similarProducts?.value?.[index];
    };

    return {
      productSlug,
      productImage,
      productSKU,
      activeSimilarProduct,
      colors,
      activeVariation,
      similarProducts,
      handleChangeSimilarProduct,
    };
  },
});
