
import {
  defineComponent,
  onMounted,
  ref,
  nextTick,
  useContext,
} from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { breakpoint } from '~/composables/breakpoint';

export default defineComponent({
  name: 'ProductSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const swiper = ref();
    const productCardsWrapper = ref();
    const { $gsap } = useContext();
    const { isBiggerThanBreakpoint } = breakpoint('lg');
    const isSliderInit = ref(false);
    const swiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 30,
      threshold: 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1536: {
          spaceBetween: 40,
        },
      },
      on: {
        /* eslint-disable */
        init: function () {
          isSliderInit.value = true;
          animateOnScroll();
        },
        /* eslint-enable */
      },
    };

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        if (!swiper.value.$el) return;
        const slides = swiper.value.$el.querySelectorAll('.swiper-slide');
        slides.forEach((slide, index) => {
          $gsap.fromTo(
            slide,
            {
              opacity: 0,
              y: 30 + index * 40,
            },
            {
              y: 0,
              opacity: 1,
              duration: 1,
              ease: 'power3.out',
              scrollTrigger: {
                trigger: swiper.value.$el,
                start: 'top center',
              },
            }
          );
        });
      });

      instance.add('(max-width: 1023px)', () => {
        if (!productCardsWrapper.value) return;
        const slides =
          productCardsWrapper.value.querySelectorAll('.product-card');
        slides.forEach((slide) => {
          $gsap.fromTo(
            slide,
            {
              opacity: 0,
              y: 50,
            },
            {
              y: 0,
              opacity: 1,
              duration: 1,
              ease: 'power3.out',
              scrollTrigger: {
                trigger: slide,
                start: 'top 70%',
              },
            }
          );
        });
      });
    };

    onMounted(() => {
      if (!isBiggerThanBreakpoint.value) {
        nextTick(() => {
          animateOnScroll();
        });
      }
    });

    return {
      isBiggerThanBreakpoint,
      isSliderInit,
      swiperOptions,
      swiper,
      productCardsWrapper,
    };
  },
});
