
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppCaption',
  props: {
    align: {
      type: String,
      default: 'left',
    },
    size: {
      type: String,
      default: 'h5',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const textAlign = computed(() => {
      if (props.align === 'left') {
        return 'xl:text-left';
      }
      return 'md:text-center';
    });

    return {
      textAlign,
    };
  },
});
